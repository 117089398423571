import React, { useState, useContext } from "react";

import styleClasses from "./RegistrationForm.module.css";
import formClasses from "../forms/Forms.module.css";
import EventContext from "../../store/EventContext";

import UserInfoContext from "../../store/UserInfoContext";

import Button from "../Button";
import RegisterButton from "./RegisterButton";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../../store/CheckoutContext";

import { SpinningCircles } from "react-loading-icons";
import { IconContext } from "react-icons";
import { CiCirclePlus } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";

const QualityAsphaltConf = () => {
  const eventCtx = useContext(EventContext);
  const userCtx = useContext(UserInfoContext);
  const checkoutCtx = useContext(CheckoutContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState();
  const [submitMessage, setSubmitMessage] = useState();
  const [didComplete, setDidComplete] = useState();

  const [payMethod, setPayMethod] = useState();

  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputRegLevel, setInputRegLevel] = useState("");
  const [inputIsMember, setInputIsMember] = useState(false);
  const [inputPDHCert, setinputPDHCert] = useState(false);
  const [inputError, setInputError] = useState("");

  const [attendees, setAttendees] = useState([]);
  // Exhibitor and Sponsorship allow for included attendees.
  const [includedAttendees, setIncludedAttendees] = useState([]);

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const calcPrice = (regLevel, isMember) => {
    const priceObj = eventCtx.selectedEvent.price_obj;
    switch (regLevel) {
      case "Exhibitor":
        return isMember
          ? priceObj.regLevel.ExhibitorMember
          : priceObj.regLevel.ExhibitorNonMember;
      case "Sponsorship":
        return isMember
          ? priceObj.regLevel.SponsorshipMember
          : priceObj.regLevel.SponsorshipNonMember;
      case "Individual":
        return isMember
          ? priceObj.regLevel.IndividualMember
          : priceObj.regLevel.IndividualNonMember;
      case "Student":
        return priceObj.regLevel.Student;
      default:
        break;
    }
  };

  const grandTotal = (atts) => {
    let total = 0;
    atts.forEach((att) => (total += +att.price));
    return total;
  };

  const AttendeeInfoInputs = () => {
    return (
      <div
        className={`${formClasses.gridThreeCol} ${formClasses.gridContainer}`}
      >
        <input
          value={inputName}
          type="text"
          placeholder="Name"
          onChange={(e) => setInputName(e.target.value)}
        />

        <input
          value={inputEmail}
          type="text"
          placeholder="Email"
          onChange={(e) => setInputEmail(e.target.value)}
        />
        <select
          value={inputRegLevel}
          name="reg-levels"
          id="reg-level"
          onChange={(e) => setInputRegLevel(e.target.value)}
        >
          <option value="">- -Registration- -</option>
          <option value="Exhibitor">Exhibitor</option>
          <option value="Sponsorship">Sponsorship</option>
          <option value="Individual">Individual</option>
          <option value="Student">Student</option>
        </select>
        <span
          className={`${formClasses.checkbox}`}
          style={{ marginBlock: "5px" }}
        >
          <label htmlFor="isMember">Member</label>
          <input
            value={inputIsMember}
            id="isMember"
            name="isMember"
            type="checkbox"
            onChange={(e) => {
              setInputIsMember(e.target.checked);
            }}
          />
        </span>
        <span
          className={`${formClasses.checkbox}`}
          style={{ marginBlock: "5px" }}
        >
          <label htmlFor="isMember">PDH Cert required</label>
          <input
            value={inputPDHCert}
            id="isPDHCert"
            name="isPDHCert"
            type="checkbox"
            onChange={(e) => {
              setinputPDHCert(e.target.checked);
            }}
          />
        </span>
        {(inputRegLevel === "Exhibitor" || inputRegLevel === "Sponsorship") && (
          <input
            type="text"
            placeholder="Included Attendee's Name"
            style={{ gridColumn: "1" }}
            onBlur={(e) =>
              setIncludedAttendees((prev) => [...prev, e.target.value])
            }
          />
        )}
        {(inputRegLevel === "Exhibitor" || inputRegLevel === "Sponsorship") && (
          <input
            type="text"
            placeholder="Included Attendee's Name"
            style={{ gridColumn: "1" }}
            onBlur={(e) =>
              setIncludedAttendees((prev) => [...prev, e.target.value])
            }
          />
        )}
        {inputRegLevel === "Sponsorship" && (
          <input
            type="text"
            placeholder="Included Attendee's Name"
            style={{ gridColumn: "1" }}
            onBlur={(e) =>
              setIncludedAttendees((prev) => [...prev, e.target.value])
            }
          />
        )}
        <div>
          <IconContext.Provider
            value={{ size: "2em", style: { padding: "0" } }}
          >
            <CiCirclePlus
              style={{ color: "#FFA559" }}
              onClick={() => {
                const currentAttendeeEntry = {
                  name: inputName,
                  email: inputEmail,
                  regLevel: inputRegLevel,
                  isMember: inputIsMember ? "Yes" : "No",
                  pdhRequired: inputPDHCert ? "Yes" : "No",
                  price: calcPrice(inputRegLevel, inputIsMember),
                  includedAttendees: includedAttendees,
                };
                if (inputRegLevel && inputName && inputEmail) {
                  setAttendees((prev) => [...prev, currentAttendeeEntry]);
                  setInputError("");
                } else {
                  setInputError("Please enter all information for Attendee");
                }
                setInputName("");
                setInputEmail("");
                setInputRegLevel(false);
                setInputIsMember(false);
                setinputPDHCert(false);
                document.getElementById("isMember").checked = false;
                document.getElementById("isPDHCert").checked = false;
              }}
            />
          </IconContext.Provider>
        </div>
      </div>
    );
  };

  const formatAttendees = (attendees) => {
    let customObj = {};
    attendees.forEach((att) => {
      customObj[
        `${att.name}`
      ] = `Email: ${att.email} | Reg-Level: ${att.regLevel}`;
      customObj["Included Attendees"] = att.includedAttendees;
      customObj["Member Status"] = att.isMember;
      customObj["PHD Cert"] = att.pdhRequired;
    });
    return customObj;
  };

  return (
    <div className={styleClasses.page}>
      <h2>{eventCtx.selectedEvent.event_date_obj.dates}</h2>
      <h4 className={styleClasses.disclaimer}>
        A block of rooms is reserved for the conference at the Golden Nugget
        Biloxi -- 151 Beach Blvd, Biloxi, MS 39530.
      </h4>
      <h4 className={styleClasses.disclaimer}>
        For reservations call 800-777-7568, use group code{" "}
        <b style={{ color: "#ffa559" }}>
          {eventCtx.selectedEvent.custom_info_obj.reservationRoomCode}
        </b>{" "}
        or online
        <a
          className={styleClasses.disclaimer}
          href={
            "https://goldennuggetbiloxi.reztrip.com/ext/promoRate?property=1262&mode=b&pm=true&sr=991566&vr=3"
          }
          target="blank"
          rel="noopener"
        >
          <b> here.</b>
        </a>
        &nbsp;Last day to make reservations is{" "}
        {eventCtx.selectedEvent.custom_info_obj.reservationLastDay}
      </h4>
      <section
        className={`${styleClasses.section} ${styleClasses.borderOrange}`}
      >
        <div
          className={`${styleClasses.backgroundOrange} ${styleClasses.headingBackground}`}
        >
          <h3>Attendees</h3>
        </div>
        <table
          className={`${styleClasses.table} ${styleClasses.flex} ${styleClasses.center} ${styleClasses.leftAlign}`}
        >
          <tbody>
            <tr>
              <th style={{ borderBottom: "1px solid white" }}>Level</th>
              <th style={{ borderBottom: "1px solid white" }}>Member</th>
              <th style={{ borderBottom: "1px solid white" }}>Non-Member</th>
            </tr>
            <tr>
              <th>Exhibitor</th>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.ExhibitorMember
                )}
              </td>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.ExhibitorNonMember
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ paddingBottom: "10px" }}>
                <i>
                  Includes: Two conference attendees, Table for product display,
                  Company logo in the program
                </i>
              </td>
            </tr>
            <tr>
              <th>Sponsorship</th>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.SponsorshipMember
                )}
              </td>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.SponsorshipNonMember
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ paddingBottom: "10px" }}>
                <i>
                  Includes: Three conference attendees, Company logo in the
                  program
                </i>
              </td>
            </tr>

            <tr>
              <th>Individual</th>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.IndividualMember
                )}
              </td>
              <td>
                {format(
                  eventCtx.selectedEvent.price_obj.regLevel.IndividualNonMember
                )}
              </td>
            </tr>
            <tr>
              <th>Student</th>
              <td>
                {format(eventCtx.selectedEvent.price_obj.regLevel.Student)}
              </td>
              <td>
                {format(eventCtx.selectedEvent.price_obj.regLevel.Student)}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {attendees.map((attendee, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <IconContext.Provider value={{ size: "30px", color: "#ffa559" }}>
              <IoCloseCircleOutline
                onClick={() => {
                  setAttendees((prev) => prev.filter((att, i) => i !== index));
                }}
              />
            </IconContext.Provider>
            <div
              className={`${styleClasses.borderOrange} ${styleClasses.infoCard}`}
              style={{ width: "100%" }}
            >
              <p>
                <b>Name:</b> {attendee["name"]}
              </p>
              <p>
                <b>Email:</b> {attendee["email"]}
              </p>
              <p>
                <b>Registration:</b> {attendee["regLevel"]}
              </p>
              <p>
                <b>Member Status:</b> {attendee["isMember"] ? "Yes" : "No"}
              </p>
              <p>
                <b>PDH Cert required</b>{" "}
                {attendee["pdhRequired"] ? "Yes" : "No"}
              </p>
              <p>
                <b>Price:</b> {format(attendee["price"])}
              </p>
              {attendee.includedAttendees.map((att, index) => (
                <p key={index} style={{ gridColumn: "1" }}>{`Included Attendee: ${att}`}</p>
              ))}
            </div>
          </div>
        ))}
        <br />
        {AttendeeInfoInputs()}
        {<p style={{ color: "#ffa559", textAlign: "center" }}>{inputError}</p>}
        {
          <h2 style={{ textAlign: "left" }}>
            Attendee Total:&nbsp;{format(grandTotal(attendees))}
          </h2>
        }
      </section>
      <br />
      {/* <h3>For more information:</h3> */}
      <a
        href={`https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/event_storage/${eventCtx.selectedEvent.event_attachment_filename}`}
        target="about_blank"
      >
        Download the PDF registration packet
      </a>
      <br />
      <br />
      <br />
      {isLoading ? (
        <SpinningCircles stroke="#ffffff30" fill="#FFF" speed="1.0" />
      ) : (
        submitMessage
      )}
      {!didComplete && (
        <RegisterButton
          isDisabled={attendees.length === 0}
          setIsLoading={setIsLoading}
          setSubmitMessage={setSubmitMessage}
          didComplete={setDidComplete}
          registrantData={{
            eventID: eventCtx.selectedEvent.id,
            event: eventCtx.selectedEvent,
            company: userCtx.company,
            firstName: userCtx.firstName,
            lastName: userCtx.lastName,
            address: `${userCtx.street} ${userCtx.city} ${userCtx.state} ${userCtx.zip}`,
            email: userCtx.email,
            phone: userCtx.phone,
            customInputs: formatAttendees(attendees),
          }}
        />
      )}
      {didComplete && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5em",
          }}
        >
          <h2>Pay Method:</h2>
          <div
            className={styleClasses.checkbox}
            style={{ marginBottom: "2em" }}
          >
            <input
              type="radio"
              name="pay-method"
              value="Check"
              onChange={() => setPayMethod("Check")}
            />
            <label htmlFor="pay-method">Check</label>
            <input
              type="radio"
              name="pay-method"
              value="Card"
              onChange={() => setPayMethod("Card")}
            />
            <label htmlFor="pay-method">Card</label>
          </div>
          {payMethod === "Check" && (
            <div style={{ marginBottom: "5em" }}>
              <h3>Please Mail Check to:</h3>
              <p style={{ margin: "0", textAlign: "center" }}>
                Mississippi Asphalt Pavement Association
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                711 N. Presidents St.
              </p>
              <p style={{ margin: "0", textAlign: "center" }}>
                Jackson, MS 39202
              </p>
            </div>
          )}
          {payMethod && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={payMethod === "Check" ? "Okay" : "Proceed to Checkout"}
              onClick={() => {
                if (payMethod === "Check") {
                  navigate("/");
                } else {
                  attendees.forEach((attendee) => {
                    checkoutCtx.addItem({
                      itemName: `QAC - ${attendee.name} ${attendee.regLevel}`,
                      itemPrice: attendee.price,
                    });
                  });
                  navigate("/checkout");
                }
                console.log("Set Item object and nav to Checkout");
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default QualityAsphaltConf;
