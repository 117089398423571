import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import emailjs from "@emailjs/browser";

import { BallTriangle } from "react-loading-icons";

import classes from "../../pages/Form.module.css";

import {
  siteIP,
  payConfirmEmailTemplate,
  emailService,
  emailJSPubKey,
} from "../../util/backendUtilities";

import Button from "../Button";

const CARD_OPTIONS = {
  iconStyle: "solid",
  disableLink: true,
  style: {
    base: {
      // backgroundColor: "#ffffff",
      iconColor: "#ffcbcb",
      // lineHeight: "35px",
      fontFamily: "Open Sans",
      color: "#000",
    },
    invalid: {
      iconColor: "#ff5858",
      color: "#ffcbcb",
    },
  },
};

const StripePaymentForm = (props) => {
  const [didAttemptPay, setDidAttemptPay] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [serverMessage, setServerMessage] = useState("");

  const {
    setDidProceed,
    didProceed,
    customerInfo,
    orderTotal,
    clearCart,
    reset,
  } = props;

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDidAttemptPay(true);
    setServerMessage("");

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        let orderString = "Order: ";
        customerInfo.orderItems.forEach(
          (item) => (orderString += `${item.itemName}- $${item.itemPrice}, `)
        );
        const response = await axios.post(`${siteIP}/payment`, {
          amount: (orderTotal * 100).toFixed(0),
          description: `${customerInfo.company} | ${orderString}`,
          id,
        });
        if (response) {
          setIsLoading(false);
        }
        if (response.data.success) {
          //Payment was successful
          setPaySuccess(true);
          setServerMessage(
            <>
              <p style={{ textAlign: "center", color: "green" }}>
                {response.data.message}
              </p>
              <p style={{ textAlign: "center" }}>
                Please check your email for confirmation.
              </p>
            </>
          );

          //Send confirmation email
          const templateParams = {
            first_name: customerInfo.firstName,
            last_name: customerInfo.lastName,
            email: customerInfo.email,
            item_list: customerInfo.orderItems,
            order_total: format(orderTotal),
            order_timestamp: new Date().toString(),
            message: "Test message from Pay Confirm",
          };

          emailjs
            .send(
              `${emailService}`,
              `${payConfirmEmailTemplate}`,
              templateParams,
              `${emailJSPubKey}`
            )
            .then(() => {
              //TODO: Delete localStorage "items" to clear cart
              localStorage.clear("items");
              clearCart([]);
              console.log("Sent");
            })
            .catch((err) => console.log(err));
        } else {
          //Payment was processed but not successfully.  ex: insufficient funds
          setIsLoading(false);
          setServerMessage(
            <>
              <p style={{ textAlign: "center", color: "red" }}>
                {response.data.message}
              </p>
              <p style={{ textAlign: "center" }}>
                Please try a different method of payment or contact the office
              </p>
              <p style={{ textAlign: "center" }}>(601) 948-5495</p>
            </>
          );
        }
      } catch (error) {
        //TODO: Set server message from with data from server
        console.log("Error", error);
        setIsLoading(false);
        setServerMessage(
          <>
            <p style={{ textAlign: "center", color: "red" }}>{error}</p>
            <p style={{ textAlign: "center" }}>Please contact the office</p>
            <p style={{ textAlign: "center" }}>(601) 948-5495</p>
          </>
        );
      }
    } else {
      //TODO: Set server message from with data from server
      console.log(error.message);
      setIsLoading(false);
      setServerMessage(
        <p style={{ textAlign: "center", color: "red" }}>{error.message}</p>
      );
    }
  };

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div>
      <form onSubmit={submitHandler}>
        <fieldset style={{ border: "none" }} className={classes.stripeInput}>
          <CardElement options={CARD_OPTIONS} />
        </fieldset>
        <br />
        <br />
        {didProceed && !didAttemptPay && (
          <>
            <div className={classes.confirmWrapper}>
              <h2>Confirm Details:</h2>
              <div className={classes.confirm}>
                <table
                  style={{
                    tableLayout: "auto",
                    width: "100%",
                    color: "black",
                  }}
                >
                  <tbody>
                    <tr>
                      <th>Name:</th>
                      <td>{`${customerInfo.firstName} ${customerInfo.lastName}`}</td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td>
                        {customerInfo.street} <br />
                        {`${customerInfo.city} ${customerInfo.state}, ${customerInfo.zip}`}
                      </td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{customerInfo.email}</td>
                    </tr>
                    <tr>
                      <th>Phone:</th>
                      <td>{customerInfo.phone}</td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>{format(orderTotal)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                backgroundColor={"#FFA559"}
                shadowColor={"#454545"}
                title={"Confirm and Pay"}
              />
            </div>
          </>
        )}
      </form>
      {didAttemptPay && isLoading && (
        <div>
          <BallTriangle stroke="#FFA559" fill="#FFA55990" speed=".70" />
          <br />
          <br />
        </div>
      )}
      {didAttemptPay && !isLoading && (
        <div className={classes.confirmWrapper}>
          <div className={classes.confirm}>{serverMessage}</div>

          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"Okay"}
            onClick={() => {
              setDidProceed(false);
              setDidAttemptPay(false);
              reset();
              elements.getElement(CardElement).clear();
            }}
          />
          <br />
          {paySuccess && (
            <p>Confirmation has been sent to the email address provided</p>
            //* At some point adding of PDF reciept may be an option
            // <Button
            //   backgroundColor={"#FFA559"}
            //   shadowColor={"#454545"}
            //   title={"Print Reciept"}
            //   onClick={() => {
            //     console.log("Setup printing of a PDF receipt");
            //   }}
            // />
          )}
        </div>
      )}
    </div>
  );
};

export default StripePaymentForm;
