import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageTitleBar from "../components/PageTitleBar";
import EventContext from "../store/EventContext";

import Form from "../components/forms/Form";
import NameInputs from "../components/forms/NameInputs";
import AddressInputs from "../components/forms/AddressInputs";
import ContactInputs from "../components/forms/ContactInputs";
import ClayShoot from "../components/event-registration/ClayShoot";
import Certification from "../components/event-registration/Certification";
import QualityAsphaltConf from "../components/event-registration/QualityAsphaltConf";
import MAPAConv from '../components/event-registration/MAPAConv'

const EventRegistration = () => {
  const navigate = useNavigate();
  const { selectedEvent } = useContext(EventContext);

  //* REDIRECT IF selectedEvent IS NULL.
  // TODO: SETUP A WAY TO PERSIST selectedEvent ON PAGE REFRESH
  useEffect(() => {
    if (!selectedEvent) {
      navigate("/events");
    }
  });

  const formSpecificInputs = (eventType) => {
    switch (eventType) {
      case "CLAY_SHOOT":
        return <ClayShoot />;
      case "CERTIFICATION":
        return <Certification />;
      case "QAC":
        return <QualityAsphaltConf />;
      case "CONVENTION":
        return <MAPAConv />;
      default:
        break;
    }
  };

  return (
    <div>
      {selectedEvent ? (
        <>
          <PageTitleBar
            title={`${selectedEvent.event_title.toUpperCase()} REGISTRATION ${new Date(
              selectedEvent.event_date
            ).getFullYear()}`}
          />
          <Form>
            <NameInputs />
            <AddressInputs />
            <ContactInputs />
            {formSpecificInputs(selectedEvent.event_type)}
          </Form>
        </>
      ) : (
        <>
          <h1>No event selected</h1>
        </>
      )}
    </div>
  );
};
export default EventRegistration;
